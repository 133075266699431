<template>
  <div>
    <loading v-if="Loading"/>

    <!-- Tabel -->
    <b-row>
      <b-col class="col-8">
        <b-form-group>
          <!-- <b-button v-if="!Loading" @click="showModal" size="sm" variant="primary">Tambah User MDT</b-button> &nbsp; -->
        </b-form-group>
      </b-col>
      <b-col class="col-4">
        <b-form-group>
          <b-input v-model="filter" placeholder="Filter Plat No"></b-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="!Loading">
      <b-col class="col-12">
        <b-table striped hover :items="filteredTableData" :fields="fields" :per-page="perPage" :current-page="currentPage" show-empty>
          <template v-slot:cell(out_by_name)="row">
            <img :src="getBadgeSrc(row.item.badge)" height="25px" /> <img :src="getColorUnit(row.item.police_type)" height="15px" style="border-radius: 80px 80px 80px 80px;" /> {{ uppercaseFirstLetter(row.item.out_by_name) }}
          </template>
        </b-table>
        <div class="d-flex justify-content-end">
          <b-pagination v-model="currentPage" :total-rows="filteredTableData.length" :per-page="perPage"></b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import axios from "axios";
import Loading from '@/templates/Loading.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import _interfaces from '@/templates/_interfaces';
import Multiselect from 'vue-multiselect';
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";


export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Loading,
    ToastificationContent,
    Multiselect,
    axios,
    quillEditor
  },
  data() {
    return {
      Loading: false,
      perPage: 10, // Jumlah item per halaman
      currentPage: 1, // Halaman saat ini
      filter: null,
      fields: [
        {key:"fullname",label:"Pemilik"},
        {key:"plate",label:"Plat"},
        {key:"modelname",label:"Model"},
        {key:"status_name",label:"Status"},
        {key:"out_by_name",label:"Dikeluarkan Oleh"},
        {key:"biaya",label:"Biaya (dalam $)"},
        {key:"tanggal_keluar",label:"Tanggal Keluar"},
      ],
      statusOption: [
        { text: 'Tampil', value: 1 },
        { text: 'Tidak Tampil', value: 0 }
      ],
      modalShow: false,
      formPasal: _interfaces.master_pasal,
      tableData: [],
      dataWarga: [],
      content: '',
      isEdit: false,
      editorOptions: {
        modules: {
          toolbar: [
            [{ 'list': 'bullet' }, { 'list': 'ordered' }], // Untuk daftar tak berurut dan berurut
            ['bold', 'italic'] // Untuk bold dan italic
          ]
        }
      }
    };
  },
  computed: {
    filteredTableData() {
      if(this.filter == null){
        return this.tableData;
      }else{
        return this.tableData.filter(item => {
          return item.plate.toLowerCase().includes(this.filter.toLowerCase())
        });
      }
    },
  },
  methods: {
    init(){
      this.getData();
    },
    getData() {
      axios.post("idp/master/kendaraan/out-list",{id:null}).then((response) => {
        this.tableData = response.data.data
      }).catch((error) => {
        if (error.status === 4000) {
          localStorage.clear();
          this.$router.push({ path: "/login" });
          ({
            title: "Login Expired",
            message: "Sesi Login Habis",
          });
        }
      }).finally(() => {
        this.Loading = false;
        this.modalShow = false;
      });
    },
    uppercaseFirstLetter(str) {
      if(str){
        // Ubah seluruh string menjadi lowercase
        str = str.toLowerCase();
        // Ambil huruf pertama dan ubah ke uppercase
        return str.charAt(0).toUpperCase() + str.slice(1);
      }
    },
    getBadgeSrc(path) {
      if(path == 'serpa.jpg'){
        return require('@/assets/images/avatars/serpa.jpg');
      }else if(path == 'serdu.jpg'){
        return require('@/assets/images/avatars/serdu.jpg');
      }else if(path == 'serpol.jpg'){
        return require('@/assets/images/avatars/serpol.jpg');
      }else if(path == 'serka.jpg'){
        return require('@/assets/images/avatars/serka.jpg');
      }else if(path == 'pelma.jpg'){
        return require('@/assets/images/avatars/pelma.jpg');
      }else if(path == 'peldu.jpg'){
        return require('@/assets/images/avatars/peldu.jpg');
      }else if(path == 'letma.jpg'){
        return require('@/assets/images/avatars/letma.jpg');
      }else if(path == 'letdu.jpg'){
        return require('@/assets/images/avatars/letdu.jpg');
      }else if(path == 'kapten.jpg'){
        return require('@/assets/images/avatars/kapten.jpg');
      }else if(path == 'mayor.jpg'){
        return require('@/assets/images/avatars/mayor.jpg');
      }else if(path == 'letkol.jpg'){
        return require('@/assets/images/avatars/letkol.jpg');
      }else if(path == 'kolonel.jpg'){
        return require('@/assets/images/avatars/kolonel.jpg');
      }else if(path == 'serjen.jpg'){
        return require('@/assets/images/avatars/serjen.jpg');
      }else if(path == 'mayjen.jpg'){
        return require('@/assets/images/avatars/mayjen.jpg');
      }else if(path == 'letjen.jpg'){
        return require('@/assets/images/avatars/letjen.jpg');
      }else if(path == 'jendral.jpg'){
        return require('@/assets/images/avatars/jendral.jpg');
      }else if(path == 'kadop.jpg'){
        return require('@/assets/images/avatars/kadop.jpg');
      }else if(path == 'sekertaris.jpg'){
        return require('@/assets/images/avatars/sekertaris.jpg');
      }
    },
    getColorUnit(police_type) {
      if(police_type == '1'){
        return require('@/assets/images/avatars/polsip.jpg');
      }else if(police_type == '2'){
        return require('@/assets/images/avatars/polmas.jpg');
      }else if(police_type == '3'){
        return require('@/assets/images/avatars/polsus.jpg');
      }
    }
  },
  mounted(){
    this.init();
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>